import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigateBackButton from "../../components/Navigation/NavigateBackButton";
import { useSession } from "../../api/context/SessionContext";
import { useShoppingCart } from "../../api/context/ShoppingCartContext";
import { ContactInfoForm, DeliveryForm } from "./CheckoutInfoPageComponents";
import { eDeliveryType } from "../../api/models/cartModel";
import { useStoreContext } from "../../api/context/StoreContext";
import { Alert } from "react-bootstrap";
import { t } from "i18next";

interface CheckoutInfoPageProps {}

const CheckoutInfoPage: FunctionComponent<CheckoutInfoPageProps> = () => {
    const navigate = useNavigate();
    const shoppingCart = useShoppingCart();
    const location = useLocation();
    const session = useSession();

    const { store } = useStoreContext();

    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        if (shoppingCart.cart.items.length === 0) {
            navigate(-1);
            return;
        }

        if (!session.isLoggedIn()) {
            navigate(
                `../login-or-signup?returnPage=${location.pathname}&cancelPage=checkout`
            );
            return;
        }

        let loggedUser = session.getLoggedUser();
        if (loggedUser) {
            const cus = {
                userId: loggedUser?.userId,
                name: loggedUser?.userName,
                email: loggedUser?.eMail,
                phone: loggedUser?.phone,
            };
            shoppingCart.cart.customer = cus;
            shoppingCart.setCustomer(cus);
            shoppingCart.setAddress(loggedUser.address);
            
            console.log("loggedUser", loggedUser);
            console.log("shoppingCart.cart", shoppingCart.cart);
            
        }
    }, []);

    const goToPayment = async () => {
        if (shoppingCart.cart.customer.name === "") {
            setErrorText(t("type_your_name"));
            return;
        }

        if (shoppingCart.cart.customer.email === "") {
            setErrorText(t("type_your_email"));
            return;
        }

        let email = shoppingCart.cart.customer.email;
        if (!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            setErrorText(t("type_valid_email"));
            return;
        }

        if (
            !shoppingCart.cart.customer.phone ||
            shoppingCart.cart.customer.phone === ""
        ) {
            setErrorText(t("type_your_phone"));
            return;
        }

        if (shoppingCart.cart.customer.phone.length < 10) {
            setErrorText(t("type_valid_phone"));
            return;
        }

        if (shoppingCart.cart.deliveryType === undefined) {
            setErrorText(t("select_delivery_type"));
            return;
        }

        if (shoppingCart.cart.deliveryType === eDeliveryType.Delivery) {
            if (!validateDelivery()) return;
        } else if (shoppingCart.cart.deliveryType === eDeliveryType.Pickup) {
            if (!validatePickup()) return;
        }

        navigate("../checkout-pay");
    };

    const validatePickup = () => {
        if (shoppingCart.getSubTotal() < store.pickupMinimumAmount) {
            alert(`${t("minimum_order_pickup")} $${store.pickupMinimumAmount}`);
            return false;
        }

        return true;
    };

    const validateDelivery = () => {
        if (!validateDeliveryAddress()) return false;

        if (shoppingCart.getSubTotal() < store.deliveryMinimumAmount) {
            alert(
                `${t("minimum_order_delivery")} $${store.deliveryMinimumAmount}`
            );
            return false;
        }

        return true;
    };

    const validateDeliveryAddress = () => {
        let address = shoppingCart.cart.deliveryAddress;
        if (!address.street) {
            setErrorText(t("type_your_address"));
            return false;
        }

        if (!address.outsideNumber) {
            setErrorText(t("type_your_address_number"));
            return false;
        }

        if (!address.neighborhood) {
            setErrorText(t("type_your_neighborhood"));
            return false;
        }

        if (!address.zipCode) {
            setErrorText(t("type_your_zip_code"));
            return false;
        }

        return true;
    };

    return (
        <div className="p-2">
            <div className="py-4 bg-white d-flex justify-content-between w-100">
                <div className="d-flex">
                    <NavigateBackButton onClick={() => navigate(-1)} />
                    <h2 className="m-0 ms-2">{t("information")}</h2>
                </div>
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => navigate(`../logout`)}
                >
                    {t("logout")}
                </button>
            </div>

            {/* Contacto */}
            <ContactInfoForm shoppingCart={shoppingCart} />

            {/* Entrega */}
            <DeliveryForm shoppingCart={shoppingCart} />

            {/* Notas */}
            <div className="form-floating mb-4">
                <textarea
                    id="notas"
                    value={shoppingCart.cart.comments}
                    style={{ height: "7rem" }}
                    className="form-control"
                    placeholder={t("notes_placeholder")}
                    onChange={(e) =>
                        shoppingCart.setComments(e.currentTarget.value)
                    }
                ></textarea>
                <label htmlFor="notas">{t("notes")}</label>
            </div>

            {errorText && (
                <Alert className="mt-4 mb-2 w-100" variant="danger">
                    {errorText}
                </Alert>
            )}

            <div className={"d-flex"}>
                <button
                    className="btn btn-sm btn-outline-success w-50 mb-3 me-2"
                    onClick={() => navigate("../")}
                >
                    {t("add_products")}
                </button>
                <button
                    className="btn btn-lg btn-success w-100 mb-3"
                    onClick={() => goToPayment()}
                >
                    {t("continue")}
                </button>
            </div>
            
        </div>
    );
};

export default CheckoutInfoPage;
