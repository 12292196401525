import {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from "react";
import React from "react";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { tSession, tSessionContext } from "../models/sessionModel";

const SessionContext = createContext({} as tSessionContext);

type tSessionContextProviderProps = {
  children: ReactNode;
};

export function SessionContextProvider({
  children,
}: tSessionContextProviderProps) {
  const [session, setSession] = useState({} as tSession);

  // Al iniciar la aplicación, se valida si existe un token en el local storage e inicia sesión con el
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      logIn(token);
    }
  }, []);

  // Regresa true si existe un usuario loggeado
  function isLoggedIn() {
    if (!session.userId) return false;
    //TODO: Validate session expiration
    return true;
  }

  // Inicia sesión con el token recibido
  function logIn(token: string) {
    // Obtiene la información del token
    try {
      const decodedToken: any = jwtDecode<JwtPayload>(token);

      // Asigna la información del token a la sesión
      let newSession = { ...session };
      newSession.userId = decodedToken.sub;
      newSession.userName = decodedToken.name;
      newSession.eMail = decodedToken.email;
      newSession.phone = decodedToken.phone;
      newSession.address = {
        street: decodedToken.address_street,
        outsideNumber: decodedToken.address_outsideNumber,
        innerNumber: decodedToken.address_innerNumber,
        neighborhood: decodedToken.address_neighborhood,
        city: decodedToken.address_city,
        state: decodedToken.address_state,
        country: decodedToken.address_country,
        zipCode: decodedToken.address_zipCode,
        lat: decodedToken.address_lat,
        lng: decodedToken.address_lng,
      };
      newSession.expires = new Date(decodedToken.exp * 1000);

      console.log("New session: ", newSession);
      
      setSession(newSession);

      // Guarda el token en el local storage
      localStorage.setItem("token", token);

      return true;
    } catch (error) {
      console.log("Error processing token: ", error);
      console.log("Token: ", token);
      return false;
    }
  }

  // Regresa la sesión del usuario loggeado
  function getLoggedUser() {
    if (isLoggedIn()) return session;
    return null;
  }

  // Cierra la sesión del usuario
  function logOut() {
    setSession({} as tSession);
    localStorage.removeItem("token");
  }

  return (
    <SessionContext.Provider
      value={{ isLoggedIn, logIn, getLoggedUser, logOut: logOut }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
