import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { t } from "i18next";
import { LoadingButton } from "../../components/Common/LoadingButton";
import { useState } from "react";
import { buildStoreFrontUrl } from "../../api/helpers/urlHelper";
import { useLocation } from "react-router-dom";

interface CheckoutPaymentFormProps {
    onSaveOrder: () => Promise<string | undefined>;
    onError: (error: string) => void;
}

const CheckoutPaymentForm = (props: CheckoutPaymentFormProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const handleSubmitPayment = async () => {
        if (!stripe || !elements) return;

        try {
            setLoading(true);

            const orderId = await props.onSaveOrder();
            if (!orderId) {
                return;
            }

            const returnUrl = buildStoreFrontUrl(
                `../checkout-complete?orderId=${orderId}`,
                location
            );

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnUrl,
                },
            });

            if (error) {
                props.onError(error.message as string);
            }
        } finally {
            setLoading(false);
        }
    };

    if (!stripe || !elements) {
        return <div>t("stripe_not_available')</div>;
    }

    return (
        <div>
            <div className="mt-3">
                <PaymentElement />
                <LoadingButton
                    className="mt-3 w-100"
                    isLoading={loading}
                    onClick={() => handleSubmitPayment()}
                >
                    {t("pay")}
                </LoadingButton>
            </div>
        </div>
    );
};

export default CheckoutPaymentForm;
